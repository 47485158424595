import { useEffect, useState } from "react";
import "./App.css";
import * as THREE from "three";
import { Reflector } from "three/examples/jsm/Addons.js";
import { Easing, Tween, update as updateTween } from "tween";
function App() {
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
  );
  const renderer = new THREE.WebGLRenderer({ antialias: true });
  const loadingManager = new THREE.LoadingManager();
  loadingManager.onLoad = () => {
    document.getElementById("loader").style.opacity = 0;
    document.getElementById("logo").style.transform = "scale(0.1)";
    document.getElementById("logo").style.opacity = 0;
    document.getElementById("logo").style.pointerEvents = 'none';
  };
  const textureLoader = new THREE.TextureLoader(loadingManager);
  const parentNode = new THREE.Object3D();
  const allImagesData = [
    {
      title: "Unbound Truth",
      artist: "SpiritualScientist",
      collection: "Stray Thoughts",
      path: "/images/unbound-truth.webp",
      widthRatio: 2,
      heightRatio: 2.75,
      magnify: 1.2,
    },
    {
      title: "Behemoth's Birth",
      artist: "SpiritualScientist",
      collection: "Stray Thoughts",
      path: "/images/behemoth-birth.webp",
      widthRatio: 2,
      heightRatio: 2.75,
      magnify: 1.2,
    },
    {
      title: "Behemoth's Discipline",
      artist: "SpiritualScientist",
      collection: "Stray Thoughts",
      path: "/images/behemoth-discipline.webp",
      widthRatio: 2,
      heightRatio: 2.75,
      magnify: 1.2,
    },
    {
      title: "Behemoth's Palace",
      artist: "SpiritualScientist",
      collection: "Stray Thoughts",
      path: "/images/behemoth-palace.webp",
      widthRatio: 2,
      heightRatio: 2.75,
      magnify: 1.2,
    },
    {
      title: "Everything/Nothing",
      artist: "SpiritualScientist",
      collection: "Reality Check",
      path: "/images/everything-nothing.webp",
      widthRatio: 2.25,
      heightRatio: 4,
      magnify: 0.8,
    },
    {
      title: "Birth of an Ophanim",
      artist: "SpiritualScientist",
      collection: "Reality Check",
      path: "/images/birth-of-ophanim.webp",
      widthRatio: 2.25,
      heightRatio: 4,
      magnify: 0.8,
    },
    {
      title: "Window to the Soul",
      artist: "SpiritualScientist",
      collection: "Reality Check",
      path: "/images/window-to-the-soul.webp",
      widthRatio: 2.25,
      heightRatio: 4,
      magnify: 0.8,
    },
    {
      title: "Unrestricted Culture",
      artist: "SpiritualScientist",
      collection: "Reality Check",
      path: "/images/unrestricted-culture.webp",
      widthRatio: 3,
      heightRatio: 3,
      magnify: 0.8,
    },
    {
      title: "Unstable Faith",
      artist: "SpiritualScientist",
      collection: "Reality Check",
      path: "/images/unstable-faith.webp",
      widthRatio: 3,
      heightRatio: 3,
      magnify: 0.8,
    },
    {
      title: "Embrace of the Eternal Will",
      artist: "SpiritualScientist",
      collection: "Crypts of a Wandering Artist",
      path: "/images/embrace-of-eternal-will.webp",
      widthRatio: 3,
      heightRatio: 3,
      magnify: 0.8,
    },
    {
      title: "Significance of Bonds",
      artist: "SpiritualScientist",
      collection: "Deeper Crypts of a Wandering Artist",
      path: "/images/s-of-bonds.webp",
      widthRatio: 3,
      heightRatio: 3,
      magnify: 0.8,
    },
    {
      title: "Spatial Nexus",
      artist: "SpiritualScientist",
      collection: "Spatial Nexus",
      path: "/images/guardian-of-light.webp",
      widthRatio: 3,
      heightRatio: 3,
      magnify: 0.8,
    },
  ];

  let currentArtwork = allImagesData[0];
  let storedZoomObjectArray = [];
  let actionObjects = {
    sideDrawerOpen: false,
    artInfoOpen: false,
    isRotating: false,
    isZooming: false,
    fullWidthArtwork: false,
    artistInfoOpen: false,
  };

  var xDown = null;
  var yDown = null;

  let artworkNames = "Unbound Truth · Behemoth's Birth · Behemoth's Discipline · Behemoth's Palace · Everything/Nothing · Birth of an Ophanim · Window to the Soul · Unrestricted Culture · Unstable Faith · Embrace of the Eternal Will · Significance of Bonds · Spatial Nexus · ";

  function getTouches(evt) {
    return evt.touches || evt.originalEvent.touches;
  }

  function handleTouchStart(evt) {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  }

  function handleTouchMove(evt) {
    if (!xDown || !yDown) {
      return;
    }
    var xUp = evt.touches[0].clientX;
    var yUp = evt.touches[0].clientY;

    var xDiff = xDown - xUp;
    var yDiff = yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      if (!actionObjects.isRotating) {
        rotateGallery(xDiff > 0 ? -1 : 1);
      }
    } else {
      if (yDiff > 0) {
        if (actionObjects.artistInfoOpen) {
          toggleDrawers("artistInfoOpen");
        }
      } else {
        if (!actionObjects.artistInfoOpen) {
          toggleDrawers("artistInfoOpen");
        }
      }
    }
    xDown = null;
    yDown = null;
  }

  useEffect(() => {
    initGalleryScene();
    document.getElementById("title").innerHTML = currentArtwork.title;
    document.getElementById("artist").innerHTML = currentArtwork.collection;

    window.addEventListener("resize", () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    });

    window.addEventListener("click", (e) => {
      const raycaster = new THREE.Raycaster();
      const mouseNDC = new THREE.Vector2(
        (e.clientX / window.innerWidth) * 2 - 1,
        -(e.clientY / window.innerHeight) * 2 + 1
      );
      raycaster.setFromCamera(mouseNDC, camera);

      let interactions = raycaster.intersectObject(scene, true);
      if (interactions?.length > 0) {
        if (
          interactions?.[0]?.object?.name.toLowerCase().includes("arrow") &&
          !actionObjects.isRotating
        ) {
          rotateGallery(interactions[0].object.name == "rightArrow" ? -1 : 1);
        }
        if (checkInteractionPair(interactions)) {
          document.getElementById("artworkImage").src = getTexturePath(
            interactions[
              interactions.findIndex((item) => item.object?.data?.path)
            ].object.data.path
          );
          if(!somethingIsHappening()) {
            toggleDrawers('artInfoOpen');
          } 
        }
      }
    });

    window.addEventListener("mousemove", (e) => {
      const raycaster = new THREE.Raycaster();
      const mouseNDC = new THREE.Vector2(
        (e.clientX / window.innerWidth) * 2 - 1,
        -(e.clientY / window.innerHeight) * 2 + 1
      );
      raycaster.setFromCamera(mouseNDC, camera);

      let interactions = raycaster.intersectObject(scene, true);
      if (interactions?.length == 2) {
        if (checkInteractionPair(interactions)) {
          if (!actionObjects.isZooming) {
            storedZoomObjectArray = interactions;
            zoomInArtwork();
          }
        }
      } else {
        zoomInArtwork(true);
      }
    });

    document.addEventListener("touchstart", handleTouchStart, false);
    document.addEventListener("touchmove", handleTouchMove, false);
  }, []);

  function initGalleryScene() {
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    let documentToRender = document.getElementById("mainContainer");
    documentToRender.appendChild(renderer.domElement);
    let leftArrowTexture = textureLoader.load("/images/left.png");
    let rightArrowTexture = textureLoader.load("/images/right.png");
    let leftArrow = new THREE.Mesh(
      new THREE.BoxGeometry(0.3, 0.3, 1),
      new THREE.MeshStandardMaterial({
        map: leftArrowTexture,
        transparent: true,
      })
    );
    leftArrow.position.set(-1.3, 0, -3);
    leftArrow.rotation.y = 0.4;
    leftArrow.name = "leftArrow";
    let rightArrow = new THREE.Mesh(
      new THREE.BoxGeometry(0.3, 0.3, 1),
      new THREE.MeshStandardMaterial({
        map: rightArrowTexture,
        transparent: true,
      })
    );
    rightArrow.position.set(1.3, 0, -3);
    rightArrow.rotation.y = -0.4;
    rightArrow.name = "rightArrow";
    allImagesData.forEach((imageItem, index) => {
      let texturePath = getTexturePath(imageItem.path);
      let texture = textureLoader.load(texturePath);
      texture.colorSpace = THREE.SRGBColorSpace;
      let baseNode = new THREE.Object3D();
      let artwork = new THREE.Mesh(
        new THREE.BoxGeometry(
          imageItem.widthRatio * (imageItem.magnify || 1),
          imageItem.heightRatio * (imageItem.magnify || 1),
          0.1
        ),
        new THREE.MeshStandardMaterial({ map: texture })
      );

      artwork.name = "artwork";
      artwork.data = imageItem;
      artwork.leftArrow =
        allImagesData[
          index - 1 > allImagesData.length - 1
            ? 0
            : index - 1 < 0
            ? allImagesData.length - 1
            : index - 1
        ];
      artwork.rightArrow =
        allImagesData[
          index + 1 > allImagesData.length + 1
            ? 0
            : index + 1 < 0
            ? allImagesData.length + 1
            : index + 1
        ];
      let border = new THREE.Mesh(
        new THREE.BoxGeometry(
          imageItem.widthRatio * (imageItem.magnify || 1) + 0.2,
          imageItem.heightRatio * (imageItem.magnify || 1) + 0.2,
          0.09
        ),
        new THREE.MeshStandardMaterial({ color: 0x303030 })
      );
      border.name = "border";
      border.position.z = -5.5;
      artwork.position.z = -5.5;
      baseNode.add(border);
      baseNode.add(artwork);
      baseNode.rotation.y = index * ((2 * Math.PI) / allImagesData.length);
      parentNode.add(baseNode);
    });
    scene.add(leftArrow);
    scene.add(rightArrow);
    scene.add(parentNode);

    let spotlight = new THREE.SpotLight(0xffffff, 100.0, 10.0, 0.65, 0.5);
    spotlight.position.set(0, 5, 0);
    spotlight.target.position.set(0, 0.5, -5);
    scene.add(spotlight);
    scene.add(spotlight.target);

    const mirror = new Reflector(new THREE.CircleGeometry(10), {
      color: 0x404040,
      textureHeight: window.innerHeight,
      textureWidth: window.innerWidth,
    });
    mirror.position.y = -1.75;
    mirror.rotateX(-Math.PI / 2);
    scene.add(mirror);
    renderer.setAnimationLoop(animate);
  }

  function rotateGallery(direction) {
    if(!somethingIsHappening()) {
      let index = allImagesData.findIndex(
        (imageItem) => imageItem.title == currentArtwork.title
      );
      let nextIndex =
        index + direction > allImagesData.length - 1
          ? 0
          : index + direction < 0
          ? allImagesData.length - 1
          : index + direction;
      currentArtwork = allImagesData[nextIndex];
      const finalPosition = -(direction * ((2 * Math.PI) / allImagesData.length));
      new Tween(parentNode.rotation)
        .to({ y: parentNode.rotation.y + finalPosition })
        .easing(Easing.Quadratic.InOut)
        .start()
        .onStart(() => {
          document.getElementById("title").style.opacity = 0;
          document.getElementById("artist").style.opacity = 0;
          changeActionObjects('isRotating', true)
        })
        .onComplete(() => {
          document.getElementById("title").innerHTML =
            allImagesData[nextIndex].title;
          document.getElementById("artist").innerHTML =
            allImagesData[nextIndex].collection;
          document.getElementById("title").style.opacity = 1;
          document.getElementById("artist").style.opacity = 1;
          changeActionObjects('isRotating', false)
        });
    }
  }

  function zoomInArtwork(isReset = false) {
    if (storedZoomObjectArray.length) {
      if (!isReset) {
        storedZoomObjectArray.forEach((objectItem) => {
          new Tween(objectItem.object.position)
            .to({ z: -4.5 })
            .easing(Easing.Quadratic.InOut)
            .start()
            .onStart(() => {
              changeActionObjects('isZooming',true);
            })
            .onComplete(() => {
              changeActionObjects('isZooming',false);
            });
          if (objectItem.object.name == "border") {
            var col = new THREE.Color(0xffffff);
            new Tween(objectItem.object.material.color)
              .to({ r: col.r, g: col.g, b: col.b })
              .easing(Easing.Quadratic.InOut)
              .start()
              .onStart(() => {
                changeActionObjects('isZooming', false);
              })
          }
        });
      } else {
        storedZoomObjectArray.forEach((objectItem) => {
          new Tween(objectItem.object.position)
            .to({ z: -5.5 })
            .easing(Easing.Quadratic.InOut)
            .start();
          if (objectItem.object.name == "border") {
            var col = new THREE.Color(0x303030);
            new Tween(objectItem.object.material.color)
              .to({ r: col.r, g: col.g, b: col.b })
              .easing(Easing.Quadratic.InOut)
              .start()
              .onStart(() => {
                changeActionObjects('isZooming', true)
              })
              .onComplete(() => {
                changeActionObjects('isZooming', false)
              });
          }
        });
        storedZoomObjectArray = [];
      }
    }
  }

  function checkInteractionPair(interactions) {
    let interactionsMap = interactions
      .map((item) => item.object.name)
      .toString();
    let isCurrentArtwork =
      interactions.findIndex(
        (item) => item.object?.data?.title == currentArtwork.title
      ) != -1;
    return (
      (interactionsMap == "artwork,border" ||
        interactionsMap == "border,artwork") &&
      isCurrentArtwork
    );
  }

  function animate() {
    updateTween();
    renderer.render(scene, camera);
  }

  function openDrawer() {
    toggleDrawers(actionObjects.artInfoOpen ? "artInfoOpen" : "artistInfoOpen");
  }

  function toggleDrawers(objectName) {
    let menuIcon = document.getElementById("menuIcon");
    let transformType =
      objectName == "artistInfoOpen" ? "translateY(" : "translateX(";
    let transformDirection = objectName == "artistInfoOpen" ? "vh" : "vw";
    document.getElementById(objectName).style.transform = actionObjects[
      objectName
    ]
      ? `${transformType}-100${transformDirection})`
      : `${transformType}0${transformDirection})`;
    menuIcon.src = !actionObjects[objectName]
      ? "images/close.png"
      : "images/menu.png";
    menuIcon.width = !actionObjects[objectName] ? 20 : 40;
    menuIcon.height = !actionObjects[objectName] ? 20 : 40;
    changeActionObjects(objectName, !actionObjects[objectName])
  }

  function toggleArtworkWidth() {
    document.getElementById("artworkImageDiv").style.width =
      actionObjects.fullWidthArtwork ? "40%" : "100%";
      changeActionObjects('fullWidthArtwork', !actionObjects.fullWidthArtwork)
  }

  function getTexturePath(path) {
    let pathSplit = path.split("/");
    let endSplit = pathSplit[pathSplit.length - 1].split(".");
    pathSplit[1] = "textures";
    pathSplit[pathSplit.length - 1] =
      endSplit[0] + "-texture." + endSplit[endSplit.length - 1];
    return pathSplit.toString().replaceAll(",", "/");
  }

  function changeActionObjects(objectName, value) {
    actionObjects[objectName] = value;
  }

  function somethingIsHappening() {
    return Object.keys(actionObjects).filter(item => actionObjects[item] && item != 'fullWidthArtwork').length > 0;
  }


  return (
    <div id="rootContainer">
      <div id="logo">
        <img
          className="loader"
          width="100"
          height="100"
          src="images/logo.jpg"
        ></img>
      </div>
      <div id="artInfoOpen">
        <div id="artworkImageDiv" onClick={() => toggleArtworkWidth()}>
          <img
            id="artworkImage"
            onLoad={() => console.log("image loaded")}
          ></img>
        </div>
      </div>
      <div id="sideDrawerOpen"></div>
      <div id="footerNav">
        <img
          className="menu"
          id="menuIcon"
          width="40"
          height="40"
          src="images/menu.png"
          onClick={() => openDrawer()}
        ></img>
      </div>
      <div id="loader">
        <div id="artworkNamesContainer">
          <div id="artworkNames">
            <span>{artworkNames}</span>
          </div>
          <div id="artworkNamesReverse">
            <span>{artworkNames}</span>
          </div>
        </div>
        <div id="artworkNamesContainerReverse">
          <div id="artworkNamesLower">
            <span>{artworkNames}</span>
          </div>
          <div id="artworkNamesReverseLower">
            <span>{artworkNames}</span>
          </div>
        </div>
      </div>
      <div id="mainContainer"></div>
      <div id="artworkTextHeader">
        <h1 id="title"></h1>
      </div>
      <div id="artworkTextFooter">
        <h2 id="artist"></h2>
      </div>
      <div id="artistInfoOpen">
        <div id="artistImageDiv">
          <img src="images/logo.jpg" id="artistImage"></img>
        </div>
        <div id="artistTextDiv">
          <span id="artistText">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </span>
        </div>
        <div id="artistTextButtons">
          <div id="artistButtonsContainer">
            <button className="mr-1">Instagram</button>
            <button className="ml-1">Twitter/X</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
